import React, { Component } from 'react';
import { validateEmail, getDeviceToken, decryptValue, deleteSesion, toReload, getCoordenadas, getInfo } from '../utils';
import { connect } from 'react-redux';
import {
    okButton, showToken, hide_okButton, setHistory,
    setOperacion, serviciosNoDisponibles, setSaldoTotal,
    setRendimientos, setTiposServicios, setPosicionCartera, setSaldoLiquido, setSaldoOperacion,
    setCoordenadas, setModalInfo, hideOperacion

} from '../redux/actions';
import { SERVICES, PATHS } from '../properties';
import { makeCall, setBitacora, encryptValues } from '../utils';
import {
    setDataUser, setContratos, setCurrentContrato,
    setChequera
} from '../redux/actionsServices';
import { ICONS } from '../assets/icons';
import Reload from './reload';
import firebase from 'firebase';
import M from 'materialize-css';
import TerminosCondiciones from './terminosCondiciones';
import AvisoPrivacidad from './avisoPrivacidad';
import { event } from 'jquery';


class NewLogin extends Component {

    state = {
        showPassword: false,
        errorpassword: false,
        erroremail: false,
        email: "",
        password: "",
        disabledForm: true,
        dataUser: false,
        loading: false
    }

    componentDidMount() {

        this.props.setHistory(this.props.history);
        this.getDisableForm();

        //         setTimeout(()=>{
        //  this.props.showToken({
        //             muestraAceptaGeoWeb: true,
        //             aceptoGeoWEB: true,
        //             onlyPass: true,
        //             idUsuario: "test@test.com.mx", accion: ACCION_CODIGO.ACCESO,
        //             message: "Apreciable cliente, ingrese un código de validación generado con la aplicación Valmex Móvil.",
        //             txtButton: "Validar",
        //             isLogin: true,
        //             title: "Código de verificación", actionClick: async (softToken) => {
        //             }});
        //         },1000)

    }



    onChangeData = (event) => {
        let { id, value } = event.target;
        // console.log(value);
        this.setState({ [`error${id}`]: false }, () => {

            if (id === "email") {
                value = value.trim().toLowerCase();
            }

            this.setState({ [id]: value }, () => {
                if (id === "email") {
                    if (!validateEmail(value)) {
                        this.setState({ erroremail: "Escribir un correo válido." });
                    }
                }

                if (id === "password") {
                    if (value.trim() === "") {
                        this.setState({ errorpassword: "Ingresa tú contraseña." });
                    }
                }

                this.getDisableForm();
            });
        });


    }

    submitData = async (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        let { password, email } = this.state;

        const getCoords = await getCoordenadas(this.props);

        if (!getCoords) {
            this.setState({ loading: false });
        }

        if (password.trim() !== "" && email.trim() !== "" && getCoords) {
            try {

                let body = SERVICES.VALIDA_USUARIO.body;
                body.IdUsuario = encryptValues(email.trim().toLowerCase());
                body.Contrasenia = encryptValues(password);
                body.FingerPrint = encryptValues(this.props.fingerPrint);
                body.latitude = encryptValues(this.props.coordenadas.latitude);
                body.longitude = encryptValues(this.props.coordenadas.longitude);
                body.Pais = encryptValues(this.props.coordenadas.pais);
                body.Ciudad = encryptValues(this.props.coordenadas.ciudad);
                body.Estado = encryptValues(this.props.coordenadas.estado);
                body.Direccion = encryptValues(this.props.coordenadas.direccion);
                body.IP = encryptValues(this.props.ip);


                let result = await makeCall(SERVICES.VALIDA_USUARIO, body, (errorCall) => {
                    this.setState({ loading: false })
                    this.serviciosDisponibles(errorCall);
                });

                if (result.response && result.response.Exito !== 200) {
                    this.setState({ loading: false });
                    let bodyCerrarSesion = SERVICES.TERMINA_SESION.body;

                    if (result.response.Exito === 401) {
                        this.props.okButton({
                            message: "No es posible iniciar sesión, ya cuenta con una sesión activa.",
                            title: "Advertencia", txtButton: "Entendido",
                            statusCode: 404,
                            actionClick: () => {
                                // bodyCerrarSesion.idUsuario = encryptValues(email.trim().toLowerCase());
                                // makeCall(SERVICES.TERMINA_SESION, bodyCerrarSesion, (errorCall) => {

                                // }).then(() => {

                                // });

                            }
                        });


                    } else if (result.response.Exito === 400) {
                        this.setState({ loading: false }, () => {
                            this.props.okButton({
                                message: "Usuario o contraseña incorrectos.", title: "Advertencia", txtButton: "Entendido",
                                statusCode: 404
                            });
                            // M.toast({html:"Usuario o contraseña incorrectos.",classes:'red darken-3'})
                        })
                    } else if (result.response.Exito === 402 || result.response.Exito === 353) {
                        this.props.okButton({
                            message: "Apreciable cliente, su cuenta está bloqueada, favor de realizar el proceso de desbloqueo.", title: "Advertencia",
                            statusCode: 404,
                            txtButton: "Entendido",
                            actionClick: () => {
                                // bodyCerrarSesion.idUsuario = encryptValues(email.trim().toLowerCase());

                                // makeCall(SERVICES.TERMINA_SESION, bodyCerrarSesion, (errorCall) => {
                                //     this.props.history.push(PATHS.DESBLOQUEO);
                                // }).then(() => {
                                //     this.props.history.push(PATHS.DESBLOQUEO);
                                // });

                                this.props.history.push(PATHS.DESBLOQUEO);

                            }
                        });
                    }
                    else if (result.response.Exito === 403) {
                        this.props.okButton({
                            message: "Usuario o contraseña incorrectos.", title: "Advertencia",
                            statusCode: 404,
                            txtButton: "Entendido",
                            actionClick: () => {
                                // bodyCerrarSesion.idUsuario = encryptValues(email.trim().toLowerCase());
                                // makeCall(SERVICES.TERMINA_SESION, bodyCerrarSesion, (errorCall) => {
                                //     this.props.history.push(PATHS.DESBLOQUEO);
                                // }).then(() => {

                                // });

                            }
                        });
                    } else if (result.response.Exito === 408) {

                        this.props.okButton({
                            message: result.response.MensajeError,
                            txtButton: "Entendido",
                            statusCode: 404,
                            actionClick: () => {
                                getCoordenadas(this.props);
                            }
                        })
                    }
                    else {
                        this.props.serviciosNoDisponibles(true);
                    }


                } else {


                    if (result && result.response) {
                        if (result.response.Exito === 200) {
                            result.response.IdUsuario = email.trim().toLowerCase();

                            if (decryptValue(result.response.Activacion_Web) === '1') { //Fue activado en web
                                this.setState({ loading: false });
                                this.props.setModalInfo({
                                    title: "¡Atención!",
                                    text: `Estimado usuario tu
                                        cuenta fue activada
                                        previamente en modo
                                        consulta y no podrás
                                        realizar operaciones.`,
                                    actionBtn: async () => {
                                        this.setState({ loading: false });
                                        this.props.setOperacion({
                                            title: `Validación de datos`,
                                            opt: "validacionDatosActivacion",
                                            data: {
                                                email: this.state.email,
                                                responseValida: result.response,
                                                esLogin: async () => {

                                                    let userFirebase = await this.loginFirebase(result.response);
                                                    firebase.analytics().logEvent('Login_inicio_sesion');

                                                    result.response.uid = userFirebase;
                                                    result.response.deviceToken = await getDeviceToken(userFirebase);
                                                    result.response.IdUsuario = result.response.IdUsuario.trim().toLowerCase();
                                                    result.response.AceptoGeoWEB = 1;

                                                    this.props.setDataUser(result.response);

                                                    await toReload({ dataUser: result.response, props: this.props });

                                                    localStorage.setItem('dataUser', JSON.stringify(result.response));

                                                    this.setState({ dataUser: result.response, loading: false }, () => { });
                                                    this.props.hideOperacion();
                                                    this.props.history.push(PATHS.INICIO);
                                                }

                                            }
                                        })


                                    }

                                })
                            } else {
                                this.setState({ loading: false });
                                this.props.setOperacion({
                                    title: `Validación de datos`,
                                    opt: "validacionDatosActivacion",
                                    data: {
                                        email: this.state.email,
                                        responseValida: result.response,
                                        esLogin: async () => {

                                            let userFirebase = await this.loginFirebase(result.response);
                                            firebase.analytics().logEvent('Login_inicio_sesion');

                                            result.response.uid = userFirebase;
                                            result.response.deviceToken = await getDeviceToken(userFirebase);
                                            result.response.IdUsuario = result.response.IdUsuario.trim().toLowerCase();
                                            result.response.AceptoGeoWEB = 1;

                                            this.props.setDataUser(result.response);

                                            await toReload({ dataUser: result.response, props: this.props });

                                            localStorage.setItem('dataUser', JSON.stringify(result.response));

                                            this.setState({ dataUser: result.response, loading: false }, () => { });
                                            this.props.hideOperacion();
                                            this.props.history.push(PATHS.INICIO);
                                        }

                                    }
                                })
                                // let userFirebase = await this.loginFirebase(result.response);
                                // try {

                                //     this.props.showToken({
                                //         muestraAceptaGeoWeb: result.response.AceptoGeoWEB != 1 ? true : false,
                                //         onlyPass: true,
                                //         idUsuario: email.trim().toLowerCase(), accion: ACCION_CODIGO.ACCESO,
                                //         message: "Apreciable cliente, ingrese un código de validación generado con la aplicación Valmex Móvil.",
                                //         txtButton: "Validar",
                                //         isLogin: true,
                                //         title: "Código de verificación", actionClick: async (softToken) => {
                                //             let bodyValidaToken = SERVICES.VALIDA_TOKEN.body;
                                //             bodyValidaToken.IdUsuario = encryptValues(email);
                                //             bodyValidaToken.SoftToken = encryptValues(softToken);
                                //             bodyValidaToken.Accion = encryptValues(ACCION_CODIGO.ACCESO);
                                //             bodyValidaToken.AceptaGeoWEB = encryptValues(1);

                                //             bodyValidaToken.Client_id = encryptValues(window.config.CLIENT_ID);
                                //             bodyValidaToken.Client_secr = encryptValues(window.config.CLIENT_SECRET);


                                //             let resultValidaToken = await makeCall(SERVICES.VALIDA_TOKEN, bodyValidaToken);

                                //             if (resultValidaToken && (resultValidaToken.response.Exito === 200 || resultValidaToken.response.Exito === 201)) {
                                //                 //Va por los contratos;
                                //                 try {
                                //                     firebase.analytics().logEvent('Login_inicio_sesion');

                                //                     result.response.uid = userFirebase;
                                //                     result.response.deviceToken = await getDeviceToken(userFirebase);
                                //                     result.response.IdUsuario = result.response.IdUsuario.trim().toLowerCase();
                                //                     result.response.AceptoGeoWEB = 1;

                                //                     this.props.setDataUser(result.response);

                                //                     await toReload({ dataUser: result.response, props: this.props });

                                //                     localStorage.setItem('dataUser', JSON.stringify(result.response));

                                //                     this.setState({ dataUser: result.response, loading: false }, () => { });

                                //                     this.props.history.push(PATHS.INICIO);


                                //                 } catch (error) {
                                //                     this.setState({ loading: false }, () => { });

                                //                     this.props.okButton({
                                //                         message: "No se lograron recuperar contratos para este usuario.",
                                //                         title: "Advertencia", txtButton: "Entendido",
                                //                         statusCode: 404
                                //                     });
                                //                 }
                                //             } else {
                                //                 deleteSesion(email, this.props);
                                //                 this.props.okButton({
                                //                     message: "Ocurrió un error inesperado al validar su token intente de nuevo.",
                                //                     title: "Advertencia", txtButton: "Entendido",
                                //                     statusCode: 404
                                //                 });
                                //             }
                                //         }
                                //     });
                                // } catch (error) {
                                //     deleteSesion(email, this.props);
                                //     this.setState({ loading: false }, () => {
                                //         this.props.okButton({
                                //             message: "No se lograron recuperar contratos para este usuario.",
                                //             title: "Advertencia", txtButton: "Entendido",
                                //             statusCode: 404
                                //         });
                                //     });
                                // }
                            }
                        }

                    } else {
                        this.setState({ loading: false }, () => {
                            deleteSesion(email, this.props);
                            this.props.okButton({
                                message: "Ocurrió un error inesperado intente de nuevo.", title: "Advertencia", txtButton: "Entendido",
                                statusCode: 404
                            });

                        });
                    }
                }
            } catch (error) {
            }
        }
    }

    render() {
        let { showPassword, erroremail, errorpassword, email,
            password, disabledForm, loading } = this.state;
        return (
            <React.Fragment>
                <div className='row' style={{ overflow: 'hidden', marginBottom: '0px' }}>
                    <div className='col s2 navyBackground' style={{ height: '100vh' }}>
                        <i className='material-icons white-text cursorPointer mt-1'
                            onClick={() => {
                               
                                let sideNavNoSesion = M.Modal.getInstance(document.getElementById('sidenavNoSesion'));

                                if (sideNavNoSesion) {
                                    sideNavNoSesion.open();
                                } else {
                                    M.Sidenav.init(document.getElementById('sidenavNoSesion'), {}).open();
                                }

                            }}

                        >menu</i>
                    </div>
                    <div className='col s10 no-plpr fondoImagenLogin'>
                        {/* <img src={ICONS.FOTO_FONDO} className="responsive-img" /> */}
                    </div>

                </div>
                <div id="formLogin" className="row newFormLogin">
                    <form onSubmit={this.submitData} className="col s12 " style={{
                        paddingLeft: '2rem',
                        paddingRight: '2rem'
                    }}>
                        <div className="row mt-10px">
                            <div className='col s12 center-align mt-1'>
                                <img src={ICONS.NEW_LOGO_LOGIN} alt="VALMEX" />
                            </div>
                            <div className="input-field col s12 mb-0px">
                                <input id="email" type="text" value={email.toLowerCase()} className={
                                    erroremail ? "invalid" : ""
                                } onChange={this.onChangeData}

                                />
                                <label htmlFor="email">Usuario</label>
                                <span className="helper-text" data-error={erroremail} ></span>
                            </div>
                            <div className="input-field col s12">
                                <img
                                    onClick={
                                        () => {
                                            this.setState({ showPassword: !showPassword });
                                        }
                                    }
                                    src={showPassword ? ICONS.HIDE : ICONS.SHOW} alt="hide" className="visibilityEyes"></img>
                                <input id="password" type={showPassword ? "text" : "password"}
                                    value={password} onChange={this.onChangeData}
                                    disabled={erroremail || email.trim() === ""}
                                    className={
                                        errorpassword ? "invalid" : ""
                                    } />
                                <label htmlFor="password">Contraseña</label>
                                <span className="helper-text" data-error={errorpassword} ></span>
                            </div>
                            <div className="col s12 center-align">
                                <span className="brandTextPrimary boldText cursorPointer"
                                    onClick={() => {
                                        firebase.analytics().logEvent('Login_olvide_contraseña');
                                        this.props.history.push(PATHS.OLVIDE_PASS);
                                    }}
                                >Olvidé mi contraseña</span>
                            </div>
                            <div className="col s12 center-align mt-1">
                                <span className="NavyPrimary cursorPointer txtUnderline ft-12"
                                    onClick={() => {
                                        firebase.analytics().logEvent('Login_reporte_robo_extravio');
                                        this.props.history.push(PATHS.ROBO_EXTRAVIO);
                                    }}
                                >Reporte de robo o extravío</span>
                            </div>

                            <div className='col s12 center-align mt-1'>
                                <div className='row'>
                                    <div className='col s10 center-align brandTextPrimary ft-12 cursorPointer'
                                        onClick={() => {
                                            this.props.setModalInfo({
                                                title: "¡Atención!",
                                                text: `Estás por activar tu cuenta únicamente en modo consulta.
El código para ingresar a tu cuenta será enviado por mail cada vez que ingreses al portal.`,
                                                boldText: `Si eres usuario de la app, no es necesario que actives tu usuario, únicamente ingresa al portal con tu usuario y contraseña.`,
                                                actionBtn: () => {
                                                    this.props.setOperacion({
                                                        title: `Validación de datos`,
                                                        opt: "validacionDatosActivacion"
                                                    })
                                                }

                                            })
                                        }}
                                        style={{ paddingLeft: '3rem', textDecoration: 'underline', paddingRight: '0px' }}
                                    >
                                        No cuento con la App Valmex Móvil, pero quiero Activar mi usuario en el Portal Valmex Web
                                    </div>
                                    <div className='col s2 left-align'>
                                        <i className='material-icons mt-1 cursorPointer ft-16' style={{ color: '#666666' }}
                                            onClick={() => {
                                                this.props.setModalInfo({
                                                    title: "¡Atención!",
                                                    text: `Te informamos que, al activar tu cuenta desde esta opción, tu perfil únicamente será para consulta y te enviaremos una clave a tu mail para ingresar en cada ocasión.`,
                                                    NoBtn: true
                                                })
                                            }}
                                        >info_outline</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col s12 center-align mt-1">
                                {
                                    loading || this.props.cargaInfo ?
                                        <Reload action={() => { }} />

                                        :
                                        <button className="btn primaryBtn"
                                            disabled={disabledForm || erroremail || errorpassword ||
                                                email.trim() === "" || password.trim() === "" || !this.props.ip
                                            }
                                        >Entrar</button>
                                }

                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 center-align">
                                <span className="brandTextPrimary boldText cursorPointer"
                                    onClick={() => {
                                        firebase.analytics().logEvent('Login_terminos_condiciones');
                                        this.props.setOperacion({
                                            title: `Términos y Condiciones`,
                                            opt: "terminos_condiciones"
                                        })
                                    }}
                                >Ver términos y condiciones</span>
                            </div>
                        </div>
                    </form>
                </div>

                <div id="modalATTerminos" className="modal">
                    <div className="modal-content">
                        <div className='row'>
                            <div className='col s12'>
                                <TerminosCondiciones data={{ esRF04: true }} />
                            </div>
                            <div className='col s12 center-align'>
                                <button className="btn primaryBtn_oc modal-close waves-effect">Aceptar</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="modalATAviso" className="modal">
                    <div className="modal-content">
                        <div className='row'>
                            <div className='col s12'>
                                <AvisoPrivacidad data={{ esRF04: true }} />
                            </div>
                            <div className='col s12 center-align'>
                                <button className="btn primaryBtn_oc modal-close waves-effect">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    serviciosDisponibles(errorCall) {

        if (errorCall && errorCall.message === 'Network Error') {
            this.props.serviciosNoDisponibles(true);
        }

    }

    getDisableForm() {
        let { erroremail, errorpassword, email, password } = this.state;

        if (erroremail || errorpassword
            ||
            email.trim() === "" || password.trim() === ""
        ) {
            this.setState({ disabledForm: true });
        } else {
            this.setState({ disabledForm: false });
        }
    }

    loginFirebase = async (validaUsr) => {
        let { email } = this.state;

        try {

            let rT = await firebase.auth().signInWithCustomToken(decryptValue(validaUsr.TokenSesion));
            // let resultFirebase = await firebase.auth().signInWithEmailAndPassword(email.trim().toLowerCase(), email.trim().toLowerCase());
            let dataB = {
                ciudad: this.props.coordenadas.ciudad,
                direccion_ip: this.props.ip,
                estado: this.props.coordenadas.estado,
                latitud: this.props.coordenadas.latitude,
                longitud: this.props.coordenadas.longitude,
                pais: this.props.coordenadas.pais,
                direccion: this.props.coordenadas.direccion

            }
            let tokenF = await rT.user.getIdToken();

            localStorage.setItem('authorization', tokenF);


            setBitacora(dataB, email.toLowerCase());
            return rT.user.uid;
        } catch (error) {
            return false;
        }
    }

}

const mapStateToProps = state => {
    let { fingerPrint, coordenadas, ip, cargaInfo } = false;

    if (state.commonsActions) {
        coordenadas = state.commonsActions.coordenadas || false;
        fingerPrint = state.commonsActions.fingerPrint || false;
        ip = state.commonsActions.ip || false;
        cargaInfo = state.commonsActions.cargaInfo || false;
    }
    return { fingerPrint, coordenadas, ip, cargaInfo };
};

export default connect(mapStateToProps, {
    okButton, showToken, hide_okButton, setDataUser,
    setContratos, setCurrentContrato, setHistory, setChequera,
    setOperacion, serviciosNoDisponibles,
    setSaldoTotal, setRendimientos, setTiposServicios,
    setPosicionCartera, setSaldoLiquido, setSaldoOperacion,
    setCoordenadas, setModalInfo, hideOperacion
})(NewLogin)
